import React, { ReactElement } from "react";
import clsx from "clsx";
import Img from "gatsby-image";
import { MarkdownContent, FluidSharpImageFile } from "@ymcansw-camping/common";

export type TeamMember = {
  readonly name: string;
  readonly role: string;
  readonly yearsOfExperience: string;
  readonly bio: string;
  readonly image: FluidSharpImageFile;
  readonly published: boolean;
};

type TeamMemberRowProps = {
  readonly member: TeamMember;
  readonly isLeft: boolean;
};

export default function TeamMemberRow({
  member,
  isLeft,
}: TeamMemberRowProps): ReactElement {
  return (
    <div className="content-block image-left-block">
      <div className="row">
        <div
          className={clsx([
            "col-xs-12 col-sm-4",
            isLeft ? "col-lg-4" : "col-sm-push-8",
          ])}
        >
          <div className="image-container">
            <Img
              className="img-fluid"
              fluid={member.image.childImageSharp.fluid}
              alt={member.name}
            />
          </div>
        </div>
        <div
          className={clsx([
            "col-xs-12 col-sm-8",
            isLeft ? "col-lg-8" : "col-sm-pull-4",
          ])}
        >
          <div className="content-box">
            <h3 className="title no-margin-top">
              {member.name}{" "}
              <span className="role">
                {" "}
                <span className="dash">- </span> {member.role}
              </span>
              <span className="exp">{member.yearsOfExperience}</span>
            </h3>
            <MarkdownContent>{member.bio}</MarkdownContent>
          </div>
        </div>
      </div>
    </div>
  );
}
